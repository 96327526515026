@import '@styles/styles.scss';

.confirmInfos {
    width: 50%;
    background-color: transparent;

    .infosTitle {
        display: none;
    }

    @include tablet {
        width: 100%;
    }
}
