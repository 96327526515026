@import '@styles/styles.scss';

.itemTag {
    button {
        background-color: $COLOR_SOFT_BLUE;
        border-radius: 32px;

        &:hover {
            background-color: $COLOR_SOFT_BLUE;
        }
        .iconTick {
            width: 15px;
        }
    }
}
