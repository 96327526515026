@import '@styles/styles.scss';

.buyConfirming {
    .itemField {
        &.unitCount,
        &.realAmount {
            .unit,
            .numeric {
                color: $primaryColor;
            }
        }
    }
}
