@import '@styles/styles.scss';

.resultedWrapper {
    .orderInfos {
        margin-top: 0;
    }

    .btnGroupWrapper {
        display: flex;
        justify-content: center;
    }

    @include tablet {
        .orderInfos {
            display: flex;
            justify-content: flex-start;
        }
    }
}
