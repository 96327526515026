@import '@styles/styles.scss';

.wrapper {
    &.sellPaying,
    &.buyPaying {
        .infosGroupWrapper {
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;
            position: relative;
            width: 40%;
            @include dividerRight();
        }

        @include tablet {
            position: relative;
            .infosGroupWrapper {
                width: auto;

                @include dividerBottom();
            }
        }
    }

    &.buyPayingManual {
        .mainInfosWrapper {
            justify-content: center;
        }
    }
}
