@import '@styles/styles.scss';

.btnCopyWrapper {
    border-radius: 6px;
    border: 1px solid $primaryColor;

    .iconCopy {
        display: none;
    }

    .btn.btnCopy {
        &.MuiButton-text {
            width: 56px;
            height: 26px;
            min-width: 56px;
            min-height: auto;
            padding: 0;
        }

        &.largeBtnCopy {
            width: 80px;
            min-width: 80px;
        }
    }
}
