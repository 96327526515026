@import '@styles/styles.scss';

.infosHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    position: relative;
    padding-bottom: 20px;
    width: 50%;

    @include dividerRight();

    @include tablet {
        padding-top: 50px;
        width: 100%;
        @include dividerBottom();
    }

    .headline {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        .title {
            margin-top: 20px;
            font-size: $FONTSIZE_24;
            text-align: center;
        }

        .iconHead {
            font-size: 50px;
            margin-right: 4px;
        }

        .tickIco,
        .processIco {
            color: $COLOR_GREEN;
        }

        .cancelIco {
            color: $COLOR_DANGERRED;
        }

        .waitingIco {
            color: $primaryColor;
        }
    }

    .message {
        font-size: $FONTSIZE_18;
        margin: 0 30px;
        text-align: center;
    }
}
