@import '@styles/styles.scss';

.actionsDialogContent {
    .contentWrapper {
        margin-bottom: 30px;

        .headline {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .mainContent {
            margin-top: 25px;
        }
    }

    .dialogActionGroup {
        justify-content: space-evenly;
    }
}
