@import '@styles/styles.scss';

.resultedWrapperTwoSimple {
    .mainInfosWrapper {
        display: flex;
        flex-direction: column;
        .infosHeader {
            padding-top: 50px;
            width: 100%;
            @include dividerBottom();
        }
    }
}
