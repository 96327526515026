@import '@styles/styles.scss';

.itemField {
    .fieldValue {
        align-items: center;
    }

    .itemFieldLeft {
        align-items: center;

        @include mobile {
            align-items: flex-start;
        }
    }

    &.realAmount {
        .itemFieldLeft {
            .fieldLabelWrapper {
                align-items: center;
            }
        }
    }

    @include mobile {
        .fieldValueWrapper {
            width: 100%;
        }
    }
}
