@import '@styles/styles.scss';

.wrapper {
    max-width: 1200px;
    margin: 0 auto;
}

.mainInfosWrapper {
    display: flex;
    padding-top: 20px;
    border: 1px solid #e5e5e5;
    border-radius: 12px;
    box-shadow: 0 2px 10px 4px rgba(37, 39, 38, 0.05);

    .infosTitle {
        font-size: $FONTSIZE_20;
        color: $primaryColor;
    }

    @include tablet {
        flex-direction: column;
    }
}

/*=============================================
=                 Buttons                     =
=============================================*/
.btnGroupWrapper {
    display: flex;
    justify-content: flex-end;

    @include desktop {
        padding: 20px 0px 20px;
    }

    @include mobile {
        justify-content: center;
    }
}

.btn {
    .content {
        .iconAddition {
            display: none;
        }
    }

    @include mobileSmall {
        text-align: center;
    }

    &.MuiButton-root,
    &.MuiButton-contained,
    &.MuiButton-outlined {
        border-radius: 12px;
    }

    &.MuiButton-contained {
        svg .fillArea {
            fill: $primaryTextContrast;
            stroke: $primaryTextContrast;
        }

        &.Mui-disabled {
            background-color: $primaryColor;
            opacity: 0.75;

            .MuiButton-label {
                color: $primaryTextContrast;
            }
        }
    }

    &.MuiButton-outlined {
        color: $primaryColor;
        background-color: transparent;
        box-shadow: none;
        border: 1px solid $primaryColor;

        &:hover {
            box-shadow: none;
            text-decoration: none;
            background-color: rgba(0, 0, 0, 0.04);
        }

        .MuiButton-label {
            color: $COLOR_STEELGREY;
        }
    }
}

/*=====  End of Buttons  ======*/

.itemField {
    &.count {
        display: none;
    }
    &.userPrice {
        display: none;
    }
    &.unitCount,
    &.realAmount {
        .unit,
        .numeric {
            color: $primaryColor;
            line-height: 1;
        }
    }
}

.snackbarWrapper {
    &.MuiAlert-standardSuccess {
        background-color: $infosBkColor;
        border: 1px solid $COLOR_MIDGREY;
    }
}
