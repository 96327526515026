@import '@styles/variables.scss';

/*=============================================
=               Template Color                =
=============================================*/
$primaryColor: $COLOR_JADEGREEN;
$infosBkColor: $COLOR_WHITE;
$primaryTextContrast: $COLOR_WHITE;
$bodyBkColor: #f9f9f9;
/*=====  End of Template Colors  ======*/
