@import '@styles/styles.scss';

.btnPending {
    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primaryTextContrast;
    }
}
