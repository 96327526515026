@import '@styles/styles.scss';

.amountInfosWrapper {
    margin-top: 0;
    .infosWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .rightInfos {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-top: 20px;

        .unitCount,
        .realAmount {
            .itemFieldLeft {
                display: flex;

                .numeric {
                    color: $primaryColor;
                }

                .unit {
                    color: $primaryColor;
                }
            }
            .fieldValueWrapper {
                display: flex;
                flex-wrap: nowrap;
            }
        }
    }
}
