@import '@styles/styles.scss';

.qrCodeBoardWrapper {
    svg {
        padding: 10px;
        background-color: $COLOR_WHITE;
        border: 1px solid $primaryColor;
        border-radius: 4px;
    }
}
